import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        2015年より株式会社モンタージュ入社。<br />
        企業PV・展示映像の制作に携わる。<br />
        主にエディター・コンポジッター・CGデザイナーとして活動。
      </p>
      <ul className="links">
        <li>
          <a href="https://seimaohga.artstation.com" target="_blank" rel="noreferrer">ポートフォリオ ↗</a>
        </li>
      </ul>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
